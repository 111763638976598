import { Components } from '@mui/material'

export const MuiCardComponent = (): Components['MuiCard'] => ({
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: {
      padding: 16,
      borderRadius: 10,
    },
  },
})
