import { Components } from '@mui/material'

export const MuiPaginationComponent = (): Components['MuiPagination'] => ({
  defaultProps: {
    variant: 'outlined',
    shape: 'rounded',
    color: 'primary',
    size: 'large',
  },
})
