import { Components } from '@mui/material'

export const MuiMenuItem = (): Components['MuiMenuItem'] => ({
  styleOverrides: {
    root: {
      fontSize: '14px',

      '&.Mui-focusVisible': {
        backgroundColor: 'unset',

        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
})
