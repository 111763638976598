import { createTheme } from '@mui/material'
import { ERROR_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, TEXT_COLOR } from 'Constants/Colors'
import { MuiBackdropComponent } from './Components/Backdrop'
import { MuiCardComponent } from './Components/Card'
import { MuiDialogComponent } from './Components/Dialog'
import { MuiMenuItem } from './Components/MenuItem'
import { MuiButtonComponent } from './Components/MuiButton'
import { MuiTabComponent } from './Components/MuiTab'
import { MuiOutlinedInputComponent } from './Components/OutlinedInput'
import { MuiPaginationComponent } from './Components/Pagination'
import { MuiPaginationItemComponent } from './Components/PaginationItem'
import { MuiPaperComponent } from './Components/Paper'
import { TypographyComponent } from './Components/Typography'

export const theme = createTheme({
  palette: {
    primary: PRIMARY_COLOR,
    error: ERROR_COLOR,
    secondary: SECONDARY_COLOR,
    text: TEXT_COLOR,
    background: {
      default: '#fff',
      paper: '#F7F7F7',
    },
    divider: '#F7F7F7',
  },
  components: {
    MuiButton: MuiButtonComponent(),
    MuiTab: MuiTabComponent(),
    MuiOutlinedInput: MuiOutlinedInputComponent(),
    MuiPaper: MuiPaperComponent(),
    MuiCard: MuiCardComponent(),
    MuiBackdrop: MuiBackdropComponent(),
    MuiDialog: MuiDialogComponent(),
    MuiPagination: MuiPaginationComponent(),
    MuiPaginationItem: MuiPaginationItemComponent(),
    MuiMenuItem: MuiMenuItem(),
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root .MuiAutocomplete-input': {
            paddingTop: 5,
            paddingBottom: 5,
          },
        },
      },
    },
  },
  typography: TypographyComponent(),
})
